import { Card, Center, Stack, Text } from "@mantine/core";

export const FooterLayout = ({ showVersion }: { showVersion?: any }) => {
	const currentYear = new Date().getFullYear();

	return (
		<Stack mt={12} spacing={16} justify="flex-end" style={{ height: "100%" }}>
			<Text size="xs" align="center">
				© {currentYear} Bahagia.day. Indonesia
			</Text>

			<Center>
				<Card style={{ width: "fit-content" }} bg={"#ebedef"} radius={"md"} py={6}>
					<Text size="xs" color="dark" align="center">
						Made with <Text component="i" className="ri-heart-fill" color="pink" size={14}></Text> by{" "}
						<Text
							size="xs"
							align="center"
							variant="link"
							component="a"
							color="pink"
							href="https://syabany.com"
							target="_blank"
							weight={600}
						>
							Syabany.com
						</Text>
					</Text>
				</Card>
			</Center>

			{showVersion &&
				(localStorage?.getItem("version") ? (
					<Text size="xs" color="dimmed" align="center">
						App Version {localStorage?.getItem("version")}
					</Text>
				) : (
					<Text size="xs" color="dimmed" align="center">
						App Version Loading ...
					</Text>
				))}
		</Stack>
	);
};
